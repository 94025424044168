import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ErrorContainer, SlidingTabs, styled, VIcon } from "@pcd/passport-ui";
import { getImageUrlEntry, PODPCDPackage } from "@pcd/pod-pcd";
import { getErrorMessage } from "@pcd/util";
import { useMemo, useState } from "react";
import { CollectablePODPCDCardBody } from "./renderers/CollectablePODPCDCardBody.js";
import { DefaultPODPCDCardBody } from "./renderers/DefaultPODPCDCardBody.js";
import { Container } from "./shared.js";
export const PODPCDUI = {
    renderCardBody: PODPCDCardBody
};
const CardWrapper = styled.div `
  padding: 12px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
`;
var PODDisplayFormat;
(function (PODDisplayFormat) {
    PODDisplayFormat["POD"] = "pod";
    PODDisplayFormat["Collectable"] = "collectable";
})(PODDisplayFormat || (PODDisplayFormat = {}));
/**
 * This component renders the body of a 'Card' that Zupass uses to display PCDs to the user.
 */
function PODPCDCardBody({ pcd }) {
    const [sigStatus, setSigStatus] = useState(0);
    const [error, setError] = useState();
    const hasCollectableContent = useMemo(() => {
        const imageUrlEntry = getImageUrlEntry(pcd);
        return imageUrlEntry?.type === "string" && imageUrlEntry.value !== "";
    }, [pcd]);
    const availableDisplayFormat = getPreferredDisplayFormat(pcd);
    const [displayFormat, setDisplayFormat] = useState(hasCollectableContent
        ? availableDisplayFormat || PODDisplayFormat.Collectable
        : PODDisplayFormat.POD);
    let content;
    if (displayFormat === PODDisplayFormat.Collectable && hasCollectableContent) {
        content = _jsx(CollectablePODPCDCardBody, { pcd: pcd });
    }
    else {
        content = _jsx(DefaultPODPCDCardBody, { pcd: pcd });
    }
    const sigButtonColor = {};
    if (sigStatus > 0) {
        sigButtonColor.color = "white";
        sigButtonColor.background = "green";
    }
    else if (sigStatus < 0) {
        sigButtonColor.color = "white";
        sigButtonColor.background = "var(--danger)";
    }
    const isValidSig = sigStatus > 0;
    return (_jsx(Container, { children: _jsxs(CardWrapper, { children: [content, _jsx("div", { style: { paddingLeft: 12 }, children: _jsxs("a", { onClick: async () => {
                            const sigResult = await verifySignature(pcd);
                            setError(sigResult.errorMessage);
                            setSigStatus(sigResult.isValid ? 1 : -1);
                        }, style: {
                            color: isValidSig ? "#5B952C" : undefined,
                            textDecoration: isValidSig ? "none" : undefined
                        }, children: [_jsx("span", { style: { paddingRight: 8 }, children: sigStatus === 0
                                    ? "Check signature"
                                    : isValidSig
                                        ? "Valid signature"
                                        : error !== undefined
                                            ? "Signature error!"
                                            : "Bad signature!" }), isValidSig && _jsx(VIcon, {}), error === undefined ? null : (_jsx(ErrorContainer, { children: error }))] }) }), hasCollectableContent && (_jsx(SlidingTabs, { initialIndex: displayFormat === PODDisplayFormat.POD ? 1 : 0, onChange: (tab) => {
                        setDisplayFormat(tab);
                    }, tabs: [
                        {
                            value: PODDisplayFormat.Collectable,
                            label: getFormatDisplayName(PODDisplayFormat.Collectable)
                        },
                        {
                            value: PODDisplayFormat.POD,
                            label: getFormatDisplayName(PODDisplayFormat.POD)
                        }
                    ] }))] }) }));
}
function getFormatDisplayName(displayFormat) {
    switch (displayFormat) {
        case PODDisplayFormat.POD:
            return "POD";
        case PODDisplayFormat.Collectable:
            return "Card";
    }
}
function getPreferredDisplayFormat(podpcd) {
    const displayEntry = podpcd.claim.entries["zupass_display"]?.value;
    if (displayEntry !== undefined &&
        typeof displayEntry === "string" &&
        displayEntry !== PODDisplayFormat.POD) {
        if (Object.values(PODDisplayFormat).indexOf(displayEntry) !== -1) {
            return displayEntry;
        }
    }
    return undefined;
}
async function verifySignature(pcd) {
    try {
        const isValid = await PODPCDPackage.verify(pcd);
        return { isValid };
    }
    catch (e) {
        return { isValid: false, errorMessage: getErrorMessage(e) };
    }
}
